/* eslint-disable react/jsx-pascal-case */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import ProgressActionWrapper from "../ProgressActionComponents/ProgressActionWrapper";
import ProgressAction from "../ProgressActionComponents/ProgressAction";
import UploadElement from "../ProgressActionComponents/UploadElement";
import { Vocabulary } from "../../Utils/Vocabulary";
import styles from "../../Styles/progressAction.module.css";
import {
  Autocomplete,
  Button,
  Divider,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import GenerateDoc from "../VehicleDasboard/GenerateDoc";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import {
  ProformaVehicle3_3,
  ProformaVehicleFileType,
  VehicleFileType,
  VehiclesStatus,
  tipFisier,
} from "../../Utils/autocompleteEnum";
import { VehicleContext } from "../../Context/vehicleContext";
import DeleteIcon from "@mui/icons-material/Delete";
import SubProgressInformation3_1 from "./ProgressInformation3/SubProgressInformation3_1";
import { getData } from "../../Services/getData";
import { urlEnum } from "../../Utils/urlEnum";
import { europeanDate } from "../../Utils/formatDate";
import moment from "moment";
import SubProgressInformation3_1Delete from "./ProgressInformation3/SubProgressInformation3_1Delete";
import { postData } from "../../Services/postData";
import ProformaFile, {
  FileDataProforma,
} from "../VehicleDasboard/GenerateFilesComponents/ProformaFile";
import { RolesEnum, TemplatesType } from "../../Utils/Constants";
import {
  PurchaseTypes,
  activeStep,
  disableStep,
  verifySellTypeOrReserve,
} from "../../Utils/utils";
import { ShowDocumentsList } from "../ProgressActionComponents/ShowDocumentsList";
import { Nomenclator } from "../../Models/VehicleModel";
import { CacheContext } from "../../Context/cacheContext";

export default function ProgressAction3() {
  const { cache }: any = useContext(CacheContext);
  const [openProforma, setOpenProforma] = useState(false);
  const vehicleContext: any = useContext(VehicleContext); //TODO
  const [openProforma3_3, setOpenProforma3_3] = useState(false);
  // const [openProforma3_4, setOpenProforma3_4] = useState(false);
  const [fileDataProforma, setFileDataProforma] = useState<FileDataProforma>({
    pretFaraTVA: 0,
  });
  const [clients, setClients] = useState([]);
  const [openReserve, setOpenReserve] = useState(false);
  const [openReserveDelete, setOpenReserveDelete] = useState(false);
  const [proforma_type3_3, setProforma_type3_3] = useState(null);
  const [proforma_type, setProforma_type] = useState(null);

  /**
   *
   */
  useEffect(() => {
    getClients();
  }, []);

  /**
   * Get clients
   */
  function getClients() {
    getData(urlEnum.clients)
      .then((res) => {
        setClients(res.data);
      })
      .catch((err) => {
        //
      });
  }
  /**
   *
   * @returns
   */
  async function saveInvoiceOrOPAdvance() {
    try {
      let localVehicleData = null;
      let index = null;
      if (proforma_type3_3 === VehicleFileType.OP) {
        const newStatus = cache.vehicleStatus.find(
          (status: Nomenclator) => status.nume === VehiclesStatus.advancePaid
        );
        localVehicleData = {
          status_id: newStatus?.id,
        };
        index = ["3.3"];
      }
      index = ["3.3", "3.4"];
      const done = await vehicleContext.saveWithFile(
        "reservedDocuments",
        tipFisier[0].nume,
        proforma_type3_3,
        () => {
          return proforma_type3_3;
        },
        index,
        null,
        localVehicleData
      );
      if (done) handleOpenProforma3_3();
    } catch (e) {
      return false;
    }
  }

  // async function saveContractAdvance() {
  //   try {
  //     const done = await vehicleContext.saveWithFile(
  //       "reservedDocuments",
  //       tipFisier[2].nume,
  //       VehicleFileType.contractAdvance,
  //       () => {
  //         return VehicleFileType.contractAdvance;
  //       },
  //       ["3.4"]
  //     );
  //     if (done) handleOpenProforma3_4();
  //   } catch (e) {
  //     return false;
  //   }
  // }

  /**
   *
   */
  const handleOpenProforma3_3 = () => {
    setOpenProforma3_3(!openProforma3_3);
  };

  // /**
  //  *
  //  */
  // const handleOpenProforma3_4 = () => {
  //   setOpenProforma3_4(!openProforma3_4);
  // };

  /**
   *
   */
  const handleModalReserve = () => {
    setOpenReserve(!openReserve);
  };
  /**
   *
   */
  const handleModalReserveDelete = () => {
    setOpenReserveDelete(!openReserveDelete);
  };

  /**
   *
   */
  async function generateProforma() {
    const data = {
      ...fileDataProforma,
      clientId: vehicleContext?.state?.vehicle?.client_id,
      userId: localStorage.getItem("useId"),
      vehicleId: vehicleContext?.state?.vehicle?.id,
      fileType: proforma_type,
      vehicle:
        proforma_type === VehicleFileType.proforma ||
        vehicleContext?.state?.vehicle?.isLeasing === PurchaseTypes.totalFunding
          ? {
              ...vehicleContext?.state?.vehicle,
              indexes: vehicleContext.updateIndex(["3.2"], null),
              modifyBy: vehicleContext.updateModifyBy([
                {
                  index: "3.2",
                  userId: localStorage.getItem("userName"),
                  date: moment().format("DD-MM-YYYY HH:mm:ss"),
                },
              ]),
            }
          : {
              ...vehicleContext?.state?.vehicle,
              modifyBy: vehicleContext.updateModifyBy([
                {
                  index: "3.2",
                  userId: localStorage.getItem("userName"),
                  date: moment().format("DD-MM-YYYY HH:mm:ss"),
                },
              ]),
            },
    };
    postData(`${urlEnum.templates}/${TemplatesType[2].name}`, data).then(
      (response) => {
        setProforma_type(null);
        setOpenProforma(false);
        vehicleContext.updateVehicle(response?.data?.vehicle);
        vehicleContext.updateVehicleDocuments(response?.data?.documents);
        vehicleContext.updateVehicleInvoices({
          clientInvoice: response.data.clientInvoice
            ? response.data.clientInvoice
            : [],
          providerInvoice: response.data.providerInvoice
            ? response.data.providerInvoice
            : [],
          OPInvoice: response.data.OPInvoice ? response.data.OPInvoice : [],
          reservedDocuments: response.data.reservedDocuments
            ? response.data.reservedDocuments
            : [],
        });
      }
    );
  }

  /**
   *
   * @param event
   */
  function onChangeFileDataProforma(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const newFileData = Object.assign({}, fileDataProforma) as any;
    newFileData[event.target.name] = event.target.value;
    setFileDataProforma(newFileData);
  }

  const modifyByArray = [];
  const tempModifyBy = vehicleContext?.state?.vehicle?.modifyBy
    ? vehicleContext?.state?.vehicle?.modifyBy
    : [];
  for (const modification of tempModifyBy) {
    if (
      modification?.index === "3.1" ||
      modification?.index === "3.2" ||
      modification?.index?.includes("3.3") ||
      modification?.index?.includes("3.4")
    )
      modifyByArray.push(modification);
  }

  /**
   * This function checks if the user has the right to access the delete button for
   * the reservation. If the vehicle has already been sold, the user needs to be an
   * administrator to access the delete button. Otherwise, the button is accessible
   * to all users.
   * @returns {boolean} Whether the user has the right to access the delete button.
   */
  const canUserAccessDeleteButton = () => {
    const roles = localStorage.getItem("roles");
    if (vehicleContext?.state?.vehicle?.data_rezervare) {
      if (roles?.includes(RolesEnum.admin)) return true;
      return false;
    }
    return true;
  };

  return (
    <ProgressActionWrapper
      title={Vocabulary.reserve_advance}
      status={disableStep(
        vehicleContext?.state?.vehicle?.isLeasing === PurchaseTypes.totalFunding
          ? "3.2"
          : "3.4",
        vehicleContext?.state?.vehicle?.indexes
      )}
      modifyBy={modifyByArray}
    >
      <Stepper className={styles.stepper} orientation="vertical">
        <Step active>
          <StepLabel>
            <Typography className={styles.stepperLabel}>
              {Vocabulary.bookingClient}
            </Typography>
          </StepLabel>
          <StepContent>
            <ProgressAction index="3.1">
              <div>
                {openReserveDelete && (
                  <SubProgressInformation3_1Delete
                    open={openReserveDelete}
                    handleModalReserve={handleModalReserveDelete}
                  ></SubProgressInformation3_1Delete>
                )}
                {openReserve && (
                  <SubProgressInformation3_1
                    clients={clients}
                    refreshClientsList={() => getClients()}
                    open={openReserve}
                    handleModalReserve={handleModalReserve}
                  />
                )}
                <div className={styles.stepContainer}>
                  <div style={{ margin: 15 }}>
                    {vehicleContext?.state?.vehicle?.client_id &&
                    vehicleContext?.state?.vehicle?.data_rezervare ? (
                      <>
                        <div style={{ display: "flex" }}>
                          <div className={styles.reserveBox}>
                            <Typography style={{ marginRight: 5 }}>
                              {Vocabulary.rezervedFor}:{" "}
                              <strong>
                                {
                                  clients?.find((element: any) => {
                                    if (
                                      element.id ===
                                      +vehicleContext?.state?.vehicle?.client_id
                                    ) {
                                      return element.nume;
                                    }
                                    return null;
                                  })?.["nume"]
                                }
                              </strong>
                            </Typography>
                            <Typography>
                              {" "}
                              {Vocabulary.atTheDate}:{" "}
                              <strong>
                                {" "}
                                {moment(
                                  vehicleContext?.state?.vehicle?.data_rezervare
                                ).format(europeanDate)}
                              </strong>
                            </Typography>

                            {vehicleContext?.state?.vehicle?.contractData
                              ?.observatii ? (
                              <Typography style={{ marginLeft: 10 }}>
                                {Vocabulary.observations}:{" "}
                                {
                                  vehicleContext?.state?.vehicle?.contractData
                                    ?.observatii
                                }
                              </Typography>
                            ) : null}
                          </div>
                        </div>
                      </>
                    ) : (
                      "Nerezervat"
                    )}
                  </div>
                  {vehicleContext?.state?.vehicle?.client_id &&
                  vehicleContext?.state?.vehicle?.data_rezervare ? (
                    <div>
                      {canUserAccessDeleteButton() && (
                        <Button
                          variant="contained"
                          style={{
                            color: "#fff",
                            padding: 15,
                            margin: 10,
                            borderRadius: 7,
                            cursor: "pointer",
                          }}
                          disabled={disableStep(
                            "4.3",
                            vehicleContext?.state?.vehicle?.indexes
                          )}
                          onClick={handleModalReserveDelete}
                        >
                          <DeleteIcon></DeleteIcon>
                        </Button>
                      )}
                    </div>
                  ) : (
                    <Button
                      style={{
                        display: "flex",
                        float: "right",
                        color: "#fff",
                        margin: 15,
                      }}
                      variant="contained"
                      onClick={handleModalReserve}
                    >
                      {Vocabulary.reserve}
                    </Button>
                  )}
                </div>
              </div>
              {/* //BUTON DE REZERVA cu modala */}
            </ProgressAction>
          </StepContent>
        </Step>
        <Step
          active={
            // vehicleContext?.state?.vehicle?.indexes?.includes("3.2") &&
            activeStep(
              "3.2",
              vehicleContext?.state?.vehicle?.indexes,
              verifySellTypeOrReserve(vehicleContext?.state?.vehicle?.isLeasing)
            )
          }
        >
          <StepLabel>
            <Typography className={styles.stepperLabel}>
              {Vocabulary.generateProforma}
            </Typography>
          </StepLabel>
          <StepContent>
            <ProgressAction index="3.2">
              <div style={{ margin: 10 }}>
                <div style={{ marginBottom: 20 }}>
                  <ShowDocumentsList
                    documents={
                      vehicleContext?.state?.invoices?.reservedDocuments
                    }
                    types={ProformaVehicleFileType}
                  />
                </div>
                <div className={styles.step3_2}>
                  <Autocomplete
                    id="tipFisier"
                    freeSolo={false}
                    disableClearable
                    disablePortal
                    getOptionLabel={(option: any) => option}
                    size="small"
                    value={proforma_type ? proforma_type : ""}
                    options={ProformaVehicleFileType}
                    onChange={(event: any, newValue: any) =>
                      setProforma_type(newValue)
                    }
                    className={styles.autocomplete3_2}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={"Tip fiser"}
                        variant="outlined"
                        size="small"
                        disabled={disableStep(
                          "4.3",
                          vehicleContext?.state?.vehicle?.indexes
                        )}
                        error={!proforma_type ? true : false}
                        helperText={
                          !proforma_type ? Vocabulary.compulsory : null
                        }
                      />
                    )}
                  />
                  <div>
                    <Button
                      style={{
                        marginLeft: "auto",
                        color: "#fff",
                      }}
                      disabled={!proforma_type}
                      variant="contained"
                      onClick={() => setOpenProforma(true)}
                    >
                      {Vocabulary.generateDownPaymentProforma}
                    </Button>
                  </div>
                </div>
                {openProforma && (
                  <GenerateDoc
                    openGenerateDoc={openProforma}
                    onCloseGenerateDocModal={() => setOpenProforma(false)}
                    generateDoc={generateProforma}
                    title={Vocabulary.generateDownPaymentProforma}
                  >
                    <ProformaFile
                      fileData={fileDataProforma}
                      onChangeFileData={onChangeFileDataProforma}
                    />
                  </GenerateDoc>
                )}
              </div>
            </ProgressAction>
            <Divider className={styles.divider}></Divider>
          </StepContent>
        </Step>
        <Step
          active={
            // vehicleContext?.state?.vehicle?.indexes?.includes("3.3") &&
            activeStep(
              "3.3",
              vehicleContext?.state?.vehicle?.indexes,
              verifySellTypeOrReserve(vehicleContext?.state?.vehicle?.isLeasing)
            )
          }
        >
          <StepLabel>
            <Typography className={styles.stepperLabel}>
              {Vocabulary.invoiceAndOP}
            </Typography>
          </StepLabel>
          <StepContent>
            <ProgressAction index="3.3">
              <div>
                <ShowDocumentsList
                  documents={vehicleContext?.state?.invoices?.reservedDocuments}
                  types={ProformaVehicle3_3}
                />
                <div style={{ width: "100%" }}>
                  <UploadElement
                    openModal={openProforma3_3}
                    handleModal={handleOpenProforma3_3}
                    handleSave={saveInvoiceOrOPAdvance}
                    handleEditInfo={(event: any) =>
                      vehicleContext.updateVehicleInvoiceType({
                        name: "reservedDocuments",
                        value: event,
                      })
                    }
                    title={Vocabulary.add}
                    disabled={disableStep(
                      "4.3",
                      vehicleContext?.state?.vehicle?.indexes
                    )}
                    fileIsRequired={true}
                  >
                    <div style={{ margin: 10 }}>
                      <Autocomplete
                        id="client"
                        freeSolo={false}
                        disableClearable
                        disablePortal
                        getOptionLabel={(option: any) => option}
                        size="small"
                        value={proforma_type3_3 ? proforma_type3_3 : ""}
                        // PaperComponent={EditButton}
                        options={ProformaVehicle3_3}
                        onChange={(event: any, newValue: any) =>
                          setProforma_type3_3(newValue)
                        }
                        sx={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={"Tip fiser"}
                            variant="outlined"
                            size="small"
                            error={!proforma_type3_3}
                            helperText={
                              proforma_type3_3 ? null : Vocabulary.compulsory
                            }
                          />
                        )}
                      />
                    </div>
                  </UploadElement>
                </div>
              </div>
            </ProgressAction>
            <Divider className={styles.divider}></Divider>
          </StepContent>
        </Step>
        {/* <Step
          active={activeStep(
            "3.4",
            vehicleContext?.state?.vehicle?.indexes,
            verifysSellTypeOrReserve(vehicleContext?.state?.vehicle?.isLeasing)
          )}
        >
          <StepLabel>
            <Typography className={styles.stepperLabel}>
              {Vocabulary.contractAdvance}
            </Typography>
          </StepLabel>
          <StepContent>
            <ProgressAction index="3.4">
              <div>
                <ShowDocumentsList
                  documents={vehicleContext?.state?.invoices?.reservedDocuments}
                  types={[
                    VehicleFileType.contractAdvanceDraft,
                    VehicleFileType.contractAdvance,
                  ]}
                />
                <div style={{ width: "100%" }}>
                  <label>{Vocabulary.signedContract}</label>
                  <UploadElement
                    openModal={openProforma3_4}
                    handleModal={handleOpenProforma3_4}
                    handleSave={saveContractAdvance}
                    handleEditInfo={(event: any) =>
                      vehicleContext.updateVehicleInvoiceType({
                        name: "reservedDocuments",
                        value: event,
                      })
                    }
                    title={Vocabulary.add}
                    disabled={disableStep(
                      "4.3",
                      vehicleContext?.state?.vehicle?.indexes
                    )}
                  ></UploadElement>
                </div>
              </div>
            </ProgressAction>
            <Divider className={styles.divider}></Divider>
          </StepContent>
        </Step> */}
      </Stepper>
    </ProgressActionWrapper>
  );
}
