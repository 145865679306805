import { Controller, useForm } from "react-hook-form";
import GenericModal from "../../Modal/GenericModal";
import { Vocabulary } from "../../Utils/Vocabulary";
import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import PartenerComercial from "../ProgressActions/ANAF/Notificare/PartenerComercial";
import DateTransport from "../ProgressActions/ANAF/Notificare/DateTransport";
import LocDeStartFinalTraseu from "../ProgressActions/ANAF/Notificare/LocDeStartFinalTraseu";
import BunuriTransport from "../ProgressActions/ANAF/Notificare/BunuriTransport";
import DocumenteTransport from "../ProgressActions/ANAF/Notificare/DocumenteTransport";
import { yupResolver } from "@hookform/resolvers/yup";
import { useContext, useEffect, useState } from "react";
import { VehicleContext } from "../../Context/vehicleContext";
import { containsLetters, getCompanyInfo, isMobile } from "../../Utils/utils";
import { tipOperatiuni } from "../../Utils/Enums/TipOperatiune";
import { getData } from "../../Services/getData";
import { urlEnum } from "../../Utils/urlEnum";
import { NotificareANAF } from "../../Models/Models";
import moment from "moment";

interface anafNotificationProps {
  openAnafNotificationModal: boolean;
  setOpenAnafNotificationModal: (state: boolean) => void;
  handleSubmitNotificationToANAF: (data: any) => void;
  selectedData: any;
  anafNotificationTitle?: string;
}

export default function AnafNotification(props: anafNotificationProps) {
  const {
    openAnafNotificationModal,
    setOpenAnafNotificationModal,
    handleSubmitNotificationToANAF,
    selectedData,
    anafNotificationTitle,
  } = props;
  const vehicleContext: any = useContext(VehicleContext);
  const [shippings, setShippings] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [bunuriTransportate, setBunuriTransportate] = useState([]);
  const [unitateMasura, setUnitateMasura] = useState([]);

  const { handleSubmit, control, setValue, watch, getValues } = useForm({
    resolver: yupResolver(NotificareANAF),
    mode: "onChange",
    reValidateMode: "onChange",
    context: undefined,
    shouldFocusError: true,
    shouldUnregister: false,
    criteriaMode: "firstError",
  });

  useEffect(() => {
    getBunuriTransportate();
    getShippings();
    getAllCompanies();
    getUnitateMasura();
  }, []);

  const watchDocumenteTransport = watch("documenteTransport");

  const watchCodOrganizatieTransportor = watch(
    "partenerComercial.codOrgTransport"
  );

  useEffect(() => {
    if (selectedData) {
      const getNotification = `${
        urlEnum.anafNotifications
      }/${selectedData?.uit.replace(/-/g, "")}`;
      getData(getNotification).then((response) => {
        const notificationData = response.data.notification[0];
        if (notificationData) {
          const bunuriTransport = JSON.parse(
            notificationData.bunuriTransportate
          );
          const dateTransport = JSON.parse(notificationData.dateTransport);
          const partenerComercial = JSON.parse(
            notificationData.partenerComercial
          );
          const locatie = JSON.parse(notificationData.locatie);
          const codPtf = JSON.parse(notificationData.codPtf);
          const documenteTransport = JSON.parse(
            notificationData.documenteTransport
          );

          Object.keys(dateTransport).forEach((key: any) => {
            setValue(`dateTransport.${key}` as any, dateTransport[key]);
            if ("codOrgTransport" === key)
              setValue(
                `dateTransport.${key}` as any,
                dateTransport.codTaraOrgTransport + dateTransport[key]
              );
            if ("dataTransport" === key)
              setValue(
                `dateTransport.${key}` as any,
                new Date(dateTransport[key])
              );
          });
          documenteTransport.forEach((document: any, index: number) => {
            Object.keys(document).forEach((key: any) => {
              setValue(
                `documenteTransport.${index}.${key}` as any,
                document[key]
              );
              if ("dataDocument" === key)
                setValue(
                  `documenteTransport.${index}.${key}` as any,
                  moment(new Date(document[key]))
                );
            });
          });

          setValue(`codPtf`, codPtf);
          setValue(`locatie`, locatie);
          setValue(`bunuriTransportate.0` as any, bunuriTransport[0]);
          setValue(`partenerComercial`, partenerComercial);
          setValue(`tipOperatie`, notificationData.tipOperatie);
          setValue(`CUI`, selectedData.CUI);
          setValue(`tipIntrare`, notificationData.tipIntrare);
          setValue(`tipIesire`, notificationData.tipIesire);
          setValue(`vinAutovehicul`, notificationData.vinAutovehicul);
          setValue(`uit`, selectedData?.uit.replace(/-/g, ""));
        }
      });
    }
  }, [selectedData]);

  useEffect(() => {
    const furnizor_id = vehicleContext.state.vehicle?.furnizor_id;
    if (furnizor_id === 3) {
      setValue("partenerComercial.denumire", "Auktion & Markt AG");
      setValue("partenerComercial.codOrgTransport", "DE232442550");
      setValue("partenerComercial.codTara", "DE");
    } else {
      const data = getValues("partenerComercial");
      if (data) {
        getCompanyInfo(
          (containsLetters(data.codOrgTransport || "")
            ? data.codOrgTransport?.substring(2)
            : data.codOrgTransport) || "",
          (containsLetters(data.codOrgTransport || "")
            ? data.codOrgTransport?.substring(0, 2)
            : data.codTara) || ""
        ).then((res: any) => {
          if (res && res.data.vatNumber?.includes("232442550") && res.data.name !== "---") setValue("partenerComercial.denumire", res.data.name);
        });
      }
    }
  }, [watchCodOrganizatieTransportor]);

  function getShippings() {
    const url = `${urlEnum.shipping}`;

    getData(url)
      .then((res: any) => {
        setShippings(res.data.shippings);
      })
      .catch((err) => {
        //
      });
  }

  function getBunuriTransportate() {
    const url = `${urlEnum.getBunuriTransportate}`;

    getData(url)
      .then((res: any) => {
        setBunuriTransportate(res.data.data);
      })
      .catch((err) => {
        //
      });
  }
  /**
   *
   */
  function getAllCompanies() {
    const url = `/${urlEnum.firma_achizitie_r}`;
    getData(url)
      .then((res) => {
        setCompanies(res.data.data);
      })
      .catch((err) => {
        //
      });
  }

  function getUnitateMasura() {
    const url = `${urlEnum.unitateMasura}`;
    getData(url)
      .then((res) => {
        setUnitateMasura(res.data.data);
      })
      .catch((err) => {
        //
      });
  }

  function getDefaultValuesForDocumenteTransport() {
    const documenteTransport = getValues("documenteTransport");
    return documenteTransport;
  }

  function getDocumenteTransport() {
    const data = getValues("documenteTransport");
    if (!data || data?.length === 0) {
      addDocument();
    }
    if (watchDocumenteTransport) {
      console.log();
    }
    const newDocs = getValues("documenteTransport");
    return newDocs;
  }

  const deleteDocument = (index: number) => {
    const data = getValues("documenteTransport");
    data.splice(index, 1);

    setValue("documenteTransport", data);
  };

  const addDocument = () => {
    const data = getValues("documenteTransport");
    if (!data) {
      setValue("documenteTransport", [
        {
          tipDocument: "10",
          numarDocument: "",
          dataDocument: "",
          // observatii: "",
        },
      ]);
    } else {
      data.push({
        tipDocument: "10",
        numarDocument: "",
        dataDocument: "",
        // observatii: "",
      });
      setValue("documenteTransport", data);
    }
  };

  const getPtfIntrareIesire = () => {
    const tipIntrare = getValues("tipIntrare");
    const tipIesire = getValues("tipIesire");
    const codPTF = getValues("codPtf");
    if (tipIntrare === "1") {
      return codPTF.codPtfIntrare;
    }
    if (tipIesire === "1") {
      return codPTF.codPtfIesire;
    }
  };

  const onChangeADRJudet = (cod: number) => {
    const data = getValues("locatie");
    const newLocationObject = {
      codJudet: cod.toString(),
      orasLocalitate: cod === 33 ? "Suceava" : "",
      denumireLocalitate: "",
      denumireStrada: cod === 33 ? "Bulevardul Sofia Vicoveanca" : "",
      numar: cod === 33 ? "52" : "",
      codPostal: cod === 33 ? "720285" : "",
      alteInfo: cod === 33 ? "Showroom Autodel" : "",
    };
    // daca nu este Suceava, nu schimb
    if (data.locatieIntrare) {
      setValue("locatie.locatieIntrare", newLocationObject);
    } else {
      setValue("locatie.locatieIesire", newLocationObject);
    }
  };

  const getCompanyBasedOnCUIorName = (filter: string) => {
    return shippings.find((company: any) => {
      return company.CIF === filter || company.name === filter;
    });
  };

  const setDateTransportor = (cod: any) => {
      const data = getValues("dateTransport");
      if (data) {
        getCompanyInfo(
          (containsLetters(cod) ? cod.substring(2) : cod) || "",
          (containsLetters(cod)
            ? cod.substring(0, 2)
            : data.codTaraOrgTransport) || ""
        ).then((res: any) => {
          if (res) {
            setValue("dateTransport.denumireOrgTransport", res.data.name);
          }
        });
      }
  };

  return (
    <GenericModal
      open={openAnafNotificationModal}
      onClose={() => setOpenAnafNotificationModal(false)}
      maxWidth={1200}
      title={
        anafNotificationTitle
          ? anafNotificationTitle
          : `${Vocabulary.anafNotificationTitle}`
      }
    >
      <p style={{ textAlign: "center", fontSize: 20 }}>
        {Vocabulary.anafNotificationMessage}
      </p>
      <form
        onSubmit={handleSubmit(handleSubmitNotificationToANAF)}
        method="get"
        id="sendNotificationToANAF"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Controller
          name="CUI"
          control={control}
          defaultValue={selectedData?.CUI || ""}
          render={({
            field: { ref, value, ...field },
            fieldState: { error },
          }) => (
            <Autocomplete
              freeSolo={false}
              style={{
                width: isMobile() ? "100%" : 320,
                marginBottom: 15,
              }}
              {...field}
              size="small"
              disableClearable
              disablePortal
              getOptionLabel={(option: any) => option.nume_firma || ""}
              id="CUI"
              onChange={(event, newValue: any) => {
                field.onChange(newValue ? newValue.CIF : "");
              }}
              value={
                companies?.find((option: any) => option.CIF === value) || null
              }
              isOptionEqualToValue={(option: any, value: any) => {
                return option.CIF === value;
              }}
              options={companies}
              getOptionDisabled={(option) => option?.access_token === null}
              renderInput={(params) => (
                <TextField
                  error={!!error}
                  helperText={error?.message}
                  label="CUI"
                  name="CUI"
                  type="search"
                  {...params}
                />
              )}
            />
          )}
        />

        <Controller
          name="tipOperatie"
          control={control}
          defaultValue={tipOperatiuni[0].id}
          render={({
            field: { ref, value, onChange, ...field },
            fieldState: { error },
          }) => (
            <Autocomplete
              freeSolo={false}
              style={{
                width: isMobile() ? "100%" : 320,
                marginBottom: 15,
              }}
              {...field}
              size="small"
              disableClearable
              disablePortal
              getOptionLabel={(option: any) => option.cod}
              id="tipOperatie"
              onChange={(event, value: any) => {
                onChange(value?.id || ""); // Update form value to the selected option's cod
              }}
              value={
                tipOperatiuni?.find((option: any) => option.id === value) ||
                undefined
              }
              options={tipOperatiuni}
              renderInput={(params) => (
                <TextField
                  error={!!error}
                  helperText={error?.message}
                  label="Tip operatiune"
                  name="tipOperatie"
                  type="search"
                  inputRef={ref}
                  {...params}
                />
              )}
            />
          )}
        />
        <PartenerComercial
          control={control}
          taraOrigine={vehicleContext.state.vehicle?.tara_origine_r?.nume}
          dataIntrarePrinPTF={watch("dateTransport")}
        />
        <DateTransport
          control={control}
          setDateTransportor={setDateTransportor}
          shippings={shippings}
          hasCodOrgOrNameTransportor={watch("dateTransport")}
          nrVehicule={
            (getCompanyBasedOnCUIorName(
              (getValues("dateTransport") as any)?.denumireOrgTransport
            ) as any)?.nrVehicul
          }
          nrRemorca={
            (getCompanyBasedOnCUIorName(
              (getValues("dateTransport") as any)?.denumireOrgTransport
            ) as any)?.nrRemorca
          }
        />
        <LocDeStartFinalTraseu
          control={control}
          locatieIntrare={watch("tipIntrare")}
          locatieIesire={watch("tipIesire")}
          onChangeADRJudet={onChangeADRJudet}
          ptfIntrareIesire={getPtfIntrareIesire}
        />
        <BunuriTransport
          control={control}
          bunuriTransportate={bunuriTransportate}
          unitateMasura={unitateMasura}
          pretAchizitie={vehicleContext.state.vehicle?.pret_achizitie_ron}
          denumireBunTransportat={vehicleContext.state.vehicle?.numeCompletVin}
        />
        <DocumenteTransport
          control={control}
          deleteDocument={deleteDocument}
          addDocument={addDocument}
          documents={getDocumenteTransport}
          defaultValue={getDefaultValuesForDocumenteTransport}
        />
        <Grid container spacing={isMobile() ? 1 : 4} style={{ padding: 10 }}>
          <Grid item md={6} xs={12}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setOpenAnafNotificationModal(false)}
              fullWidth
            >
              {Vocabulary.no}
            </Button>
          </Grid>
          <Grid item md={6} xs={12}>
            <Button
              variant="contained"
              fullWidth
              style={{ color: "white" }}
              type="submit"
              form="sendNotificationToANAF"
            >
              {Vocabulary.yes}
            </Button>
          </Grid>
        </Grid>
      </form>
    </GenericModal>
  );
}
