import { useEffect, useState } from "react";
import { componentNames } from "../../Utils/Constants";
import withRole from "../../Utils/withRole";
import SalaryComponent from "./SalaryComponent";
import { useNavigate, useParams } from "react-router-dom";
import { urlEnum } from "../../Utils/urlEnum";
import { getData } from "../../Services/getData";

function Dashboard(props: any) {
  const params = useParams();
  const navigate = useNavigate();
  const [hasPermission, setHasPermission] = useState(false);

  useEffect(() => {
    const userId = localStorage?.useId;
    const accessedUserId = params?.id;
    const permissions = localStorage?.permissions;
    const hasPermissionToseeUserSalary = permissions.includes("usersSalary");
    if (accessedUserId)
      checkForPermission(userId, accessedUserId, hasPermissionToseeUserSalary);
    else setHasPermission(true);
  }, []);

  /**
   *
   * @param userId
   * @returns
   */
  const getManagedUserIds = async (userId: string) => {
    const url = urlEnum.user + "/" + userId;
    const response = await getData(url);
    if (response?.data?.data) {
      return response?.data?.data[0]?.managed_user_ids;
    }
    return [];
  };

  /**
   *
   * @param userId
   * @param accessedUserId
   * @param hasPermissionToseeUserSalary
   */
  const checkForPermission = async (
    userId: string,
    accessedUserId: string | undefined,
    hasPermissionToseeUserSalary: boolean
  ) => {
    if (accessedUserId != userId) {
      //if the user dosent have permission to see other users salary, redirect
      const managedUserIds = await getManagedUserIds(userId);
      if (!hasPermissionToseeUserSalary && accessedUserId) {
        if (accessedUserId != userId) navigate("/*");
      } else if (hasPermissionToseeUserSalary) {
        if (managedUserIds) {
          //if the user manages other users, he should only see the salary of user he manages
          const managesUser = managedUserIds.some(
            (user: any) => user == accessedUserId
          );
          if (!managesUser) navigate("/*");
        }
      }
    }
    setHasPermission(true);
  };

  return (
    hasPermission && <SalaryComponent name={componentNames.SalaryComponent} />
  );
}
export default withRole(Dashboard);
