import { Vocabulary } from "../../Utils/Vocabulary";
import { Grid, Paper, TextField, Typography } from "@mui/material";
import style from "../../Styles/vehicleInfo.module.css";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { urlEnum } from "../../Utils/urlEnum";
import { updateData } from "../../Services/updateData";
import { getData } from "../../Services/getData";
import { CacheContext } from "../../Context/cacheContext";
import { NumericFormatCustom } from "../../Utils/NumericFormatComponent";

const iniatialState = {
  trimester1: { targetVolum: "", comisionCA: "", comisionProfit: "" },
  trimester2: { targetVolum: "", comisionCA: "", comisionProfit: "" },
  trimester3: { targetVolum: "", comisionCA: "", comisionProfit: "" },
  trimester4: { targetVolum: "", comisionCA: "", comisionProfit: "" },
  ComisionVanzare: "",
  IDCostComisionAchizitie: "",
  IDCostComisionLogistica: "",
  ComisionTargetVolum: "",
  ComisionTargetCA: "",
  ComisionTargetProfit: "",
  minimumCommission: "",
  taxForLeasingBonus: "",
  generalTax: "",
  socialInsurance: "",
  healthInsurance: "",
  incomeTax: "",
  minimalSellingTarget: "",
};
export default function SalaryCOnfiguration() {
  const [state, setState] = useState(iniatialState);
  const cacheContext = useContext(CacheContext);
  const cacheData = cacheContext.cache;

  /**
   * Get salary settings
   */
  useEffect(() => {
    getSettings();
  }, []);

  /**
   *
   * @param page
   * @param perPage
   * @param search
   */
  async function getSettings() {
    const url = `${urlEnum.settings}`;
    const settings = await getData(url);
    // if (
    //   settings.data?.data?.trimester1 &&
    //   settings.data?.data?.trimester2 &&
    //   settings.data?.data?.trimester3 &&
    //   settings.data?.data?.trimester4
    // )
    setState({
      ...state,
      trimester1: settings.data?.data?.trimester1 ?? iniatialState.trimester1,
      trimester2: settings.data?.data?.trimester2 ?? iniatialState.trimester2,
      trimester3: settings.data?.data?.trimester3 ?? iniatialState.trimester3,
      trimester4: settings.data?.data?.trimester4 ?? iniatialState.trimester4,
      ComisionVanzare: settings.data.data?.ComisionVanzare,
      IDCostComisionAchizitie: settings.data.data?.IDCostComisionAchizitie,
      IDCostComisionLogistica: settings.data.data?.IDCostComisionLogistica,
      ComisionTargetVolum: settings.data.data?.ComisionTargetVolum,
      ComisionTargetCA: settings.data.data?.ComisionTargetCA,
      ComisionTargetProfit: settings.data.data?.ComisionTargetProfit,
      minimumCommission:
        settings?.data?.data.minimumCommission ??
        iniatialState.minimumCommission,
      taxForLeasingBonus:
        settings?.data?.data.taxForLeasingBonus ??
        iniatialState.taxForLeasingBonus,
      generalTax: settings?.data?.data.generalTax ?? iniatialState.generalTax,
      socialInsurance:
        settings?.data?.data.socialInsurance ?? iniatialState.socialInsurance,
      healthInsurance:
        settings?.data?.data.healthInsurance ?? iniatialState.healthInsurance,
      incomeTax: settings?.data?.data.incomeTax ?? iniatialState.incomeTax,
      minimalSellingTarget: settings?.data?.data.minimalSellingTarget ?? iniatialState.minimalSellingTarget,
    });
  }

  /**
   * update state
   * @param event
   * @param trimester
   */
  const handleChangeTextFields = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    trimester = null
  ) => {
    if (trimester) {
      const newObj = Object.assign(state)[trimester];
      newObj[event.target.name] = event.target.value;
      setState({ ...state, [trimester]: newObj });
    } else setState({ ...state, [event.target.name]: event.target.value });
  };

  /**
   *
   * @param trimester
   * @param number
   * @returns
   */
  const renderTrimester = (trimester: any, number: number) => {
    return (
      <fieldset
        style={{
          border: "1px solid #0000001f",
          borderRadius: "10px",
          marginTop: 20,
        }}
      >
        <legend>
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: 400,
              padding: "0 10px",
            }}
          >
            {Vocabulary.Trimester + " " + number}
          </Typography>
        </legend>
        <TextField
          type="number"
          name="targetVolum"
          InputLabelProps={{
            shrink: true,
          }}
          className={style.textField}
          label={Vocabulary.targetVolum}
          fullWidth
          value={Object.assign(state)[trimester]?.targetVolum ?? ""}
          onChange={(event) => handleChangeTextFields(event, trimester)}
        />

        <TextField
          // type="number"
          name="comisionProfit"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            inputComponent: NumericFormatCustom,
          }}
          className={style.textField}
          label={Vocabulary.profitCommission}
          fullWidth
          value={Object.assign(state)[trimester]?.comisionProfit ?? ""}
          onChange={(event) => handleChangeTextFields(event, trimester)}
        />
        <TextField
          //type="number"
          name="comisionCA"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            inputComponent: NumericFormatCustom,
          }}
          className={style.textField}
          label={Vocabulary.CACommission}
          fullWidth
          value={Object.assign(state)[trimester]?.comisionCA ?? ""}
          onChange={(event) => handleChangeTextFields(event, trimester)}
        />
      </fieldset>
    );
  };

  /**
   * refreshes the cache data with the new values
   * @param data
   */
  const refreshCache = (data: any) => {
    const id = Object.keys(data.data);
    const tempLocalStorage = Object.assign({}, cacheData);
    id.forEach((element) => {
      if (cacheData[element]) tempLocalStorage[element] = data.data[element];
    });
    cacheContext.setCache(tempLocalStorage);
  };

  /**
   * save settings
   * @returns
   */
  const updateSalarySettings = async () => {
    const url = `${urlEnum.settings}`;
    const res = await updateData(url, state);
    if (!res) {
      return;
    }
    refreshCache(res);
  };

  return (
    <>
      <Paper
        style={{
          padding: 15,
          marginBottom: 35,
          borderRadius: "0px 0px 15px 15px ",
        }}
      >
        <ValidatorForm
          method="get"
          onSubmit={updateSalarySettings}
          id="settingsPlatform"
          style={{ marginTop: 10 }}
        >
          <Grid container spacing={1}>
            {Object.keys(state).map((trimester: any, key: number) => {
              if (key <= 3)
                return (
                  <Grid item xs={12} md={3} key={key}>
                    {renderTrimester(trimester, key + 1)}
                  </Grid>
                );
            })}

            <Grid item xs={6} md={3}>
              <TextField
                type="number"
                name="ComisionVanzare"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: <>%</>,
                }}
                className={style.textField}
                label={Vocabulary.comisionVanzare}
                value={state?.ComisionVanzare}
                onChange={(event) => handleChangeTextFields(event)}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                type="number"
                name="IDCostComisionAchizitie"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: <>%</>,
                }}
                className={style.textField}
                label={Vocabulary.comisionAchizitie}
                fullWidth
                value={state?.IDCostComisionAchizitie}
                onChange={(event) => handleChangeTextFields(event)}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                type="number"
                name="IDCostComisionLogistica"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: <>%</>,
                }}
                className={style.textField}
                label={Vocabulary.comisionLogistica}
                fullWidth
                value={state?.IDCostComisionLogistica}
                onChange={(event) => handleChangeTextFields(event)}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                type="number"
                name="ComisionTargetVolum"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: <>%</>,
                }}
                className={style.textField}
                label={Vocabulary.targetCommision}
                fullWidth
                value={state.ComisionTargetVolum}
                onChange={(event) => handleChangeTextFields(event)}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                type="number"
                name="ComisionTargetCA"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: <>%</>,
                }}
                className={style.textField}
                label={Vocabulary.CACommission}
                fullWidth
                value={state.ComisionTargetCA}
                onChange={(event) => handleChangeTextFields(event)}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                type="number"
                name="ComisionTargetProfit"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: <>%</>,
                }}
                className={style.textField}
                label={Vocabulary.profitCommission}
                fullWidth
                value={state.ComisionTargetProfit}
                onChange={(event) => handleChangeTextFields(event)}
              />
            </Grid>

            <Grid item xs={6} md={3}>
              <TextField
                type="number"
                name="minimumCommission"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: <>%</>,
                }}
                className={style.textField}
                label={Vocabulary.minimumCommission}
                fullWidth
                value={state.minimumCommission}
                onChange={(event) => handleChangeTextFields(event)}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                type="number"
                name="taxForLeasingBonus"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: <>%</>,
                }}
                className={style.textField}
                label={Vocabulary.taxForLeasingBonus}
                fullWidth
                value={state.taxForLeasingBonus}
                onChange={(event) => handleChangeTextFields(event)}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                type="number"
                name="generalTax"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: <>%</>,
                }}
                className={style.textField}
                label={Vocabulary.generalTax}
                fullWidth
                value={state.generalTax}
                onChange={(event) => handleChangeTextFields(event)}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                type="number"
                name="socialInsurance"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: <>%</>,
                }}
                className={style.textField}
                label={Vocabulary.socialInsurance}
                fullWidth
                value={state.socialInsurance}
                onChange={(event) => handleChangeTextFields(event)}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                type="number"
                name="healthInsurance"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: <>%</>,
                }}
                className={style.textField}
                label={Vocabulary.healthInsurance}
                fullWidth
                value={state.healthInsurance}
                onChange={(event) => handleChangeTextFields(event)}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                type="number"
                name="incomeTax"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: <>%</>,
                }}
                className={style.textField}
                label={Vocabulary.incomeTax}
                fullWidth
                value={state.incomeTax}
                onChange={(event) => handleChangeTextFields(event)}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextField
                type="number"
                name="minimalSellingTarget"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: <>%</>,
                }}
                className={style.textField}
                label={Vocabulary.minimalSellingTarget}
                fullWidth
                value={state.minimalSellingTarget}
                onChange={(event) => handleChangeTextFields(event)}
              />
            </Grid>
          </Grid>
        </ValidatorForm>
      </Paper>
    </>
  );
}
