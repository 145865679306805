import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import { Vocabulary } from "../../../../Utils/Vocabulary";
import { Controller} from "react-hook-form";
import { isMobile } from "../../../../Utils/utils";
import { euCountries } from "../../../../Utils/Constants";
import ReplayIcon from "@mui/icons-material/Replay";

export default function DateTransport(props: any) {
  const {
    control,
    nrVehicule,
    nrRemorca,
    shippings,
    setDateTransportor,
    hasCodOrgOrNameTransportor,
    codOrgTransport,
  } = props;

  return (
    <fieldset
      style={{
        border: "1px solid #0000001f",
        borderRadius: 10,
        marginTop: 10,
      }}
    >
      <legend
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "0rem 0.8rem",
        }}
      >
        {Vocabulary.dateTransport}
      </legend>
      {codOrgTransport && (
        <Button
          variant="contained"
          startIcon={<ReplayIcon />}
          onClick={() => setDateTransportor(codOrgTransport)}
          style={{ color: "white" }}
        >
          {Vocabulary.tryAgain}
        </Button>
      )}
      <Grid container spacing={isMobile() ? 1 : 3} style={{ marginTop: 5 }}>
        <Grid item md={3} xs={12}>
          <Controller
            name="dateTransport.codTaraOrgTransport"
            control={control}
            defaultValue={
              euCountries?.find((tara) => tara.name === "Romania")?.code
            }
            render={({
              field: { ref, onChange, value, ...field },
              fieldState: { error },
            }) => (
              <Autocomplete
                freeSolo={false}
                style={{
                  marginBottom: 15,
                }}
                size="small"
                disableClearable
                disablePortal
                getOptionLabel={(option: any) => option.name}
                id="dateTransport.codTaraOrgTransport"
                // onChange={(event, value: any) => field.onChange(value.code)}
                onChange={(event, value: any) => {
                  onChange(value?.code || ""); // Update form value to the selected option's cod
                }}
                value={
                  euCountries?.find((option: any) => option.code === value) ||
                  undefined
                }
                options={euCountries}
                renderInput={(params) => (
                  <TextField
                    error={!!error}
                    helperText={error?.message}
                    label={Vocabulary.taraTransportor}
                    name="dateTransport.codTaraOrgTransport"
                    type="search"
                    inputRef={ref}
                    {...params}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Controller
            name="dateTransport.codOrgTransport"
            control={control}
            defaultValue={
              shippings?.filter(
                (option: any) =>
                  option.CIF != null &&
                  option.CIF !== undefined &&
                  option.CIF ===
                    (typeof hasCodOrgOrNameTransportor?.codOrgTransport ===
                      "string" && hasCodOrgOrNameTransportor?.codOrgTransport)
              )[0]?.CIF || ""
            }
            render={({
              field: { ref, onChange, onBlur, value, ...field },
              fieldState: { error },
            }) => (
              <Autocomplete
                freeSolo={true} // Allow free solo input
                style={{
                  marginBottom: 15,
                }}
                size="small"
                disableClearable
                disablePortal
                getOptionLabel={(option: any) => {
                  return option.CIF ? option.CIF : option;
                }}
                id="dateTransport.codOrgTransport"
                onChange={(event, value: any) => {
                  if (typeof value === "string") {
                    setDateTransportor(value || "");
                    onChange(value); // Allow the user to input a custom value
                  } else {
                    setDateTransportor(value?.CIF || "");
                    onChange(value?.CIF || ""); // Update form value to the selected option's CIF
                  }
                }}
                value={
                  value
                  //shippings?.find((option: any) => option.CIF === value) ? shippings?.find((option: any) => option.CIF === value): value ? value: ""
                }
                options={shippings?.filter(
                  (option: any) =>
                    option.CIF != null && option.CIF !== undefined
                )} // Filter out null or undefined values
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!error}
                    helperText={error?.message}
                    label={Vocabulary.codOrgTransport}
                    name="dateTransport.codOrgTransport"
                    type="search"
                    inputRef={ref}
                    onBlur={(e) => {
                      const inputValue = e.target.value;
                      setDateTransportor(inputValue);
                      onChange(inputValue);
                      onBlur();
                    }}
                  />
                )}
              />
            )}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <Controller
            name="dateTransport.denumireOrgTransport"
            control={control}
            render={({
              field: { ref, onChange, onBlur, value, ...field },
              fieldState: { error },
            }) => (
              <Autocomplete
                freeSolo={true} // Allow free solo input
                style={{
                  marginBottom: 15,
                }}
                size="small"
                disableClearable
                disablePortal
                getOptionLabel={(option: any) => option.name || option}
                id="dateTransport.denumireOrgTransport"
                onChange={(event, value: any) => {
                  if (typeof value === "string") {
                    setDateTransportor(value);
                    onChange(value);
                  } else {
                    setDateTransportor(value?.name || "");
                    onChange(value?.name || "");
                  }
                }}
                onBlur={(e) => {
                  const inputValue = (e.target as any).value;
                  setDateTransportor(inputValue);
                  onChange(inputValue);
                  onBlur();
                }}
                value={
                  shippings?.find((option: any) => option.name === value) ||
                  value ||
                  ""
                }
                options={shippings}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!error}
                    helperText={error?.message}
                    label={Vocabulary.denumireTransportor}
                    name="dateTransport.denumireOrgTransport"
                    type="search"
                    inputRef={ref}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Controller
            name="dateTransport.nrVehicul"
            control={control}
            defaultValue={undefined}
            render={({
              field: { ref, onChange, onBlur, value, ...field },
              fieldState: { error },
            }) => (
              <Autocomplete
                freeSolo={true} // Allow values not in the dropdown
                style={{
                  marginBottom: 15,
                }}
                size="small"
                disableClearable
                disablePortal
                disabled={
                  hasCodOrgOrNameTransportor?.codOrgTransport ||
                  hasCodOrgOrNameTransportor?.denumireOrgTransport
                    ? false
                    : true
                }
                getOptionLabel={(option: any) => option}
                id="dateTransport.nrVehicul"
                onChange={(event, value: any) => {
                  if (typeof value === "string") {
                    onChange(value); // Allow the user to input a custom value
                  } else {
                    onChange(value || ""); // Update form value to the selected option's CIF
                  }
                }}
                value={
                  (nrVehicule &&
                    JSON.parse(nrVehicule)?.find(
                      (option: any) => option === value
                    )) ||
                  value || // Display the input value if it's not in the list
                  ""
                }
                options={nrVehicule ? JSON.parse(nrVehicule) : []}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!error}
                    helperText={error?.message}
                    label={Vocabulary.nrInmatriculareVehiculCapTractor}
                    name="dateTransport.nrVehicul"
                    type="search"
                    inputRef={ref}
                    onBlur={(e) => {
                      onChange(e.target.value); // Update form value on blur
                      onBlur(); // Call the original onBlur event
                    }}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Controller
            name="dateTransport.nrRemorca"
            control={control}
            defaultValue={undefined}
            render={({
              field: { ref, onChange, onBlur, value, ...field },
              fieldState: { error },
            }) => (
              <Autocomplete
                freeSolo={true} // Allow values not in the dropdown
                style={{
                  marginBottom: 15,
                }}
                size="small"
                disableClearable
                disablePortal
                disabled={
                  hasCodOrgOrNameTransportor?.codOrgTransport ||
                  hasCodOrgOrNameTransportor?.denumireOrgTransport
                    ? false
                    : true
                }
                getOptionLabel={(option: any) => option}
                id="dateTransport.nrRemorca"
                onChange={(event, value: any) => {
                  if (typeof value === "string") {
                    onChange(value);
                  } else {
                    onChange(value || "");
                  }
                }}
                value={
                  (nrRemorca &&
                    JSON.parse(nrRemorca)?.find(
                      (option: any) => option === value
                    )) ||
                  value ||
                  ""
                }
                options={nrRemorca ? JSON.parse(nrRemorca) : []}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!error}
                    helperText={error?.message}
                    label={Vocabulary.nrRemorca}
                    name="dateTransport.nrRemorca"
                    type="search"
                    inputRef={ref}
                    onBlur={(e) => {
                      onChange(e.target.value);
                      onBlur();
                    }}
                  />
                )}
              />
            )}
          />
        </Grid>
      </Grid>
    </fieldset>
  );
}
